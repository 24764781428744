import Gadgets from "./components/gadgets/Gadgets";
import Navbar from "./components/navbar/Navbar";
import {Routes, Route} from "react-router-dom";
import Home from "./pages/home/Home";
import Eliqsar from "./pages/eliqsar/Eliqsar";
import PrivacyPolicy from "./pages/eliqsar/PrivacyPolicy";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Eliqsar" element={<Eliqsar />} />
        <Route path="/Eliqsar/privacy_policy/" element={<PrivacyPolicy />} />
      </Routes>
      <Gadgets />
    </>
  );
}

export default App;
