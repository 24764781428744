import React from "react";
import {
  BitCoin1,
  Hero,
  HeroContainer,
  SpiningSetting,
  SpinnerBox,
} from "./hero.style";

const HeroSection = () => {
  return (
    <>
      <BitCoin1 right={true} />
      <Hero>
        <SpinnerBox>
          <SpiningSetting />
        </SpinnerBox>
        <HeroContainer />
      </Hero>
    </>
  );
};

export default HeroSection;
