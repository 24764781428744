import styled from "styled-components";
import {FiSettings} from "react-icons/fi";
import {Container} from "../../styles/globalStyles";
import Bitcoin from "../../assets/images/HeroLogo.png";
import Bitcoin2 from "../../assets/images/AppLogo.jpg";

export const Hero = styled.div`
  z-index: 13;
  background: unset;
`;

export const SpinnerBox = styled.div`
  z-index: 13;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  background-color: #1c3976;
  top: 20em;
  width: 52.44px;
  height: 50px;
`;

export const SpiningSetting = styled(FiSettings)`
  z-index: 11;
  width: 25px;
  height: 25px;
  color: #fff;

  @media (prefers-reduced-motion: no-preference) {
    animation: App-logo-spin infinite 2s linear;
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const HeroContainer = styled(Container)`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 700px;
  background: unset;
  width: 100vw;
`;
export const BitCoin1 = styled.div`
  position: fixed;
  width: 100vw;
  height: 40vh;
  margin-top: 10vh;
  transition: 0.6s all ease-in-out;
  background-image: url(${Bitcoin});
  background-repeat: repeat;
  background-size: cover;
  @media only screen and (max-width: 960px) {
    right: 30px;
  }
`;

export const BitCoin2 = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  transition: 0.6s all ease-in-out;
  right: ${({right}) => (right ? `-100% ` : `0`)};
  background-image: url(${Bitcoin2});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const MotivationMessage = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  @media only screen and (max-width: 524px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 420px) {
    font-size: 15px;
  }
`;

export const HeroMessage = styled.h4``;
