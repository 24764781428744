import React from "react";
import About from "../../components/about/About";
import HeroSection from "../../components/hero/HeroSection";
import {HomePage} from "./home.style";

const Home = () => {
  return (
    <HomePage>
      <HeroSection />
      <About />
    </HomePage>
  );
};

export default Home;
