import React from "react";
import {
  AppContainer,
  Container,
  PrivacyImage,
  PrivacyPolicyWrapper,
} from "../../styles/globalStyles";
import appLogo from "../../assets/images/adaptive-icon.png";
import privacy1 from "../../assets/images/Privacy1.jpg";
import privacy2 from "../../assets/images/Privacy2.jpg";
import privacy3 from "../../assets/images/Privacy3.jpg";
import privacy4 from "../../assets/images/Privacy4.jpg";
import privacy5 from "../../assets/images/Privacy5.jpg";
import privacy6 from "../../assets/images/Privacy6.jpg";
import privacy7 from "../../assets/images/Privacy7.jpg";
import privacy8 from "../../assets/images/Privacy8.jpg";
import privacy9 from "../../assets/images/Privacy9.jpg";
import privacy10 from "../../assets/images/Privacy10.jpg";
import privacy11 from "../../assets/images/Privacy11.jpg";
import privacy12 from "../../assets/images/Privacy12.jpg";
import privacy13 from "../../assets/images/Privacy13.jpg";
import privacy14 from "../../assets/images/Privacy14.jpg";

function PrivacyPolicy() {
  return (
    <Container>
      <AppContainer>
        <h2 style={{color: "#e862f4", fontWeight: "bold", fontSize: 30}}>
          Eliqsar App Privacy Policy
        </h2>
        <img src={appLogo} style={{width: 100, height: 100}} />
        <PrivacyPolicyWrapper>
          <PrivacyImage src={privacy1} />
          <PrivacyImage src={privacy2} />
          <PrivacyImage src={privacy3} />
          <PrivacyImage src={privacy4} />
          <PrivacyImage src={privacy5} />
          <PrivacyImage src={privacy6} />
          <PrivacyImage src={privacy7} />
          <PrivacyImage src={privacy8} />
          <PrivacyImage src={privacy9} />
          <PrivacyImage src={privacy10} />
          <PrivacyImage src={privacy11} />
          <PrivacyImage src={privacy12} />
          <PrivacyImage src={privacy13} />
          <PrivacyImage src={privacy14} />
        </PrivacyPolicyWrapper>
      </AppContainer>
    </Container>
  );
}

export default PrivacyPolicy;
